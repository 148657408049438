/*
 * @Description:
 * @Author: zjc
 * @Date: 2021-12-07 14:54:53
 * @LastEditTime: 2024-11-11 11:10:15
 * @LastEditors: zjc
 */
import { defineStore } from 'pinia'
import { setStore, getStore, removeStore } from '@/utils/store'
import { USER_API } from '@/service/user'
import { LIBRARY_API } from '@/service/library/index'
import { Book_API } from '@/service/partyLibrary/index'
import { SYSTEM_API } from '@/service/system/index.js'
import useMsg from '@/hooks/useMsg'
const { useNotification } = useMsg()
import { COMMON_API } from '@/utils/common'
// import { routers } from '@/utils/fixedRouter'
import { routers } from '@/utils/threeLevel'
import useJump from '@/hooks/useCommon.js'
import { ORG_API } from '@/service/org/index.js'
export const adminStore = defineStore('user', {
  state: () => {
    return {
      orgInfo: getStore({ name: 'orgInfo' }) || '',
      organization: getStore({ name: 'organization' }) || '',//组织
      operator: getStore({ name: 'operator' }) || '',//操作员

      token: getStore({ name: 'userToken' }) || '',

      roleName: getStore({ name: 'roleName' }) || '',
      roleId: getStore({ name: 'roleId' }) || '',
      userId: getStore({ name: 'userId' }) || '',
      userInfo: getStore({ name: 'userInfo' }) || {},
      name: getStore({ name: 'name' }) || '',
      menuList: getStore({ name: 'menuList' }) || [],
      subMenuList: getStore({ name: 'subMenuList' }) || [],//二级、三级菜单
      permission: [],//按钮权限列表
      libraryInfo: getStore({ name: 'libraryInfo' }) || {},//图书馆信息
      menuUrl: getStore({ name: 'menuUrl' }) || '/oms/dashboard',//二级菜单url
      libraryList: getStore({ name: 'libraryList' }) || [],//当前图书馆列表
      libraryLists: getStore({ name: 'libraryLists' }) || [],//当前图书馆列表
      authCode: getStore({ name: 'authCode' }) || [],//三级权限
      showSublevel: getStore({ name: 'showSublevel' }) //显示是否含子部门
    }
  },
  getters: {
    getOrganization: state => state.organization,//组织
    getOperator: state => state.operator,//操作员
    getUserId: state => state.userId,
    getMenuList: state => state.menuList,
    getSubMenuList: state => state.subMenuList,
    getName: state => state.name,
    getToken: state => state.token,
    getUserInfo: state => state.userInfo,
    libraryData: state => state.libraryInfo,
    getLibraryList: state => state.libraryList,
    getLibraryLists: state => state.libraryLists,
    getOrgInfo: state => state.orgInfo,
    getAuthCode: state => state.authCode,
    getShowSublevel: state => state.showSublevel,
  },
  actions: {
    set_auth_code (codeList) {
      this.authCode = codeList
      setStore({ name: 'authCode', content: codeList })
    },
    setOrgInfo (orgInfo) {
      this.orgInfo = orgInfo
      setStore({ name: 'orgInfo', content: orgInfo })
    },
    setUser (userInfo) {
      this.userInfo = userInfo
      setStore({ name: 'userInfo', content: userInfo })
    },
    // 登录
    loginByUsername (userInfo) {
      return new Promise((resolve, reject) => {
        USER_API.loginByUserName(userInfo).then((res) => {

          resolve(res)
        }).catch(err => {
          reject(err)
        })
      })
    },

    // 当前获取图书馆列表
    getLibraries () {
      return new Promise((resolve, reject) => {
        SYSTEM_API.getLibraries().then(res => {
          let list = []
          if (res && res.data && res.data.length) {
            res.data.forEach(item => {
              if (item.isEnabled == 1) {
                list.push(item)
              }
            })
          }
          this.libraryList = list
          setStore({ name: 'libraryList', content: list })
          resolve(list)
        })
      })
    },
    // 当前获取图书馆列表
    getLibrariesv2 (obj) {
      return new Promise((resolve, reject) => {
        SYSTEM_API.getLibrariesv2({ ...obj, isEnabled: 1 }).then(res => {
          this.libraryLists = res.data.records
          setStore({ name: 'libraryLists', content: this.libraryLists })
          resolve(this.libraryLists)
        })
      })
    },
    // 获取角色是否可以查看包含子部门的数据
    async getUserPermission (id) {
      if (id) {
        const { data } = await ORG_API.memberInfo(id);
        if (data.roles && data.roles.length) {
          if (data.roles.findIndex(i => i.dsType == 1 || i.dsType == 3) > -1) {
            setStore({ name: 'showSublevel', content: true })
            this.showSublevel = true

          } else {
            setStore({ name: 'showSublevel', content: false })
            this.showSublevel = false
          }
        } else {
          setStore({ name: 'showSublevel', content: false })
          this.showSublevel = false

        }

      } else {
        setStore({ name: 'showSublevel', content: false })
        this.showSublevel = false
      }
    },
    // 退出登录
    logOut () {
      return new Promise((resolve, reject) => {
        this.USER_TOKEN('')
        this.USER_ID('')
        this.USER_NAME('')
        this.setUser({})
        this.SET_MENU_ALL([])
        this.setMenuUrl('')
        setStore({ name: 'pageNum', content: 1 })
        removeStore({ name: 'userToken' })
        removeStore({ name: 'orgInfo' })
        removeStore({ name: 'tenantId' })
        removeStore({ name: 'showSublevel' })

        // removeStore({ name: 'showSublevel' })
        resolve()
      })
    },
    h5LogOut () {
      return new Promise((resolve, reject) => {
        this.USER_TOKEN('')
        removeStore({ name: 'userToken' })
        resolve()
      })
    },
    sortord (property) {
      return function (a, b) {
        var value1 = a[property];
        var value2 = b[property];
        return value1 - value2; //正序
        // return value2 - value1; //倒叙
      }
    },
    getInfo () {
      return new Promise((resolve, reject) => {
        USER_API.permissionCollection().then((res) => {
          let newArr = []
          if (res && res.data && res.data.length) {
            res.data = res.data.sort(this.sortord("indexNo"))
            res.data = res.data.sort(this.sortord("id"))
            res.data.forEach(item => {
              if (item.permissionStatus == 1) {
                item.authUrl = item.permissionRoute
                item.authName = item.permissionName
                item.id = item.permissionId
                item.parentId = item.parentPermissionId
                item.authCode = item.permissionCode
                item.authIcon = "icon16_common_nav"
                if (item.parentPermissionId == 0) {
                  item.children = []
                  newArr.push(item)
                }
              }
            });

            let arr = COMMON_API.deepClone(res.data)
            for (let i = 0; i < newArr.length; i++) {
              for (let j = 0; j < arr.length; j++) {
                arr[j].children = []
                if (newArr[i].permissionId == arr[j].parentPermissionId && arr[j].permissionStatus == 1 && arr[j].permissionRoute != '/mobile/leaderview') { //隐藏领导视图
                  newArr[i].children.push(arr[j])
                }
              }
            }
            let getList = []
            for (let i = 0; i < arr.length; i++) {
              for (let j = 1; j < arr.length; j++) {
                if (arr[i].id == arr[j].parentPermissionId && arr[i].parentPermissionId != 0) {
                  getList.push(arr[j].permissionCode)
                }
              }
            }
            this.set_auth_code(getList)
          } else {
            useNotification({ msg: '暂无权限登录后台，请联系管理员。', type: 'error' })
            logOut()
            return
          }
          this.SET_MENU_ALL(newArr)
          this.SET_DATA(newArr)
          resolve(newArr)
        }).catch(err => {
          reject(err)
        })
      })
    },
    getLibraryInfo () {
      return new Promise((resolve, reject) => {
        LIBRARY_API.libraryInfo().then((res) => {
          resolve(res.data)
        }).catch(err => {
          reject(err)
        })
      })
    },
    USER_LIBRARYLIST (list) {
      this.libraryList = list
      console.log(list, 'list')
      setStore({ name: 'libraryList', content: list })
    },
    SET_DATA (data) {
      const { org, opr } = data
      this.organization = org
      this.operator = opr
      setStore({ name: 'organization', content: org })
      setStore({ name: 'operator', content: opr })
    },
    USER_TOKEN (token) {
      this.token = token
      setStore({ name: 'userToken', content: token, type: true })
    },
    USER_NAME (name) {
      this.name = name
      setStore({ name: 'name', content: name })
    },
    USER_ID (id) {
      this.userId = id
      setStore({ name: 'userId', content: id })
    },
    SET_ROLE_ID (roleId) {
      this.roleId = roleId
      setStore({ name: 'roleId', content: roleId })
    },
    SET_MENU_ALL (menuList = []) {
      let indexList = {
        authIcon: "icon16_common_nav",
        authName: "首页",
        authUrl: "/oms/dashboard",
        id: 1,
        children: [],
      }

      this.menuList = COMMON_API.filterMenu(menuList)
      this.menuList = menuList
      this.menuList.unshift(indexList)

      this.menuList.push(...routers) //临时本地路由，后期删除
      this.menuList = COMMON_API.filterMenu(this.menuList) //后期删除
      setStore({ name: 'menuList', content: this.menuList })
    },
    SET_SUBMENU_LIST (menuList) {
      this.subMenuList = COMMON_API.filterMenu(menuList)
      setStore({ name: 'subMenuList', content: menuList })
    },
    SET_LIBRARY (obj) {
      this.libraryInfo = obj
      setStore({ name: 'libraryInfo', content: obj })

    },

    //开通馆配
    OPEN_LDP () {
      return new Promise((resolve, reject) => {
        Book_API.getLdpOpen().then((res) => {
          const { data } = res
          if (data) {
            resolve(this.OPEN_DIALOG())
          } else {
            Book_API.registerLdp().then((res) => {
              resolve(this.OPEN_DIALOG())
            })
          }
        }).catch(err => {
          reject(err)
        })
      })
    },
    OPEN_DIALOG () {
      return new Promise((resolve, reject) => {
        Book_API.ldpToken().then((res) => {
          resolve(res.data)
        }).catch(err => {
          reject(err)
        })
      })
    },
    //缓存二级菜单url
    setMenuUrl (url) {
      this.menuUrl = url
      setStore({ name: 'menuUrl', content: url })

    }
  },
})
/*
 * @Description:
 * @Author: zjc
 * @Date: 2021-12-07 11:49:39
 * @LastEditTime: 2024-06-12 10:06:42
 * @LastEditors: zjc
 */
import router from '@/router'
import { libraryTitle } from '@/theme/theme'
import { COMMON_API } from '@/utils/common'
import { getStore, setStore } from '@/utils/store'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
import { commonStore } from '@/store/common.js'
import { adminStore } from '@/store/user.js'
import { authStore } from '@/store/permissions.js'
import { routers } from '@/utils/fixedRouter'

NProgress.configure({ showSpinner: false });

//不需要登录
const whiteList = ['/', '/forgot-password', '/select-org',
  '/invite-library', '/download-library', '/text',
  '/mobile',
  '/mobile/leaderview',
  '/mobile/leaderview/org',
  '/mobile/leaderview/orgUser',
  '/mobile/download',
  '/public/invite',
]
let menulibrary = [
  '/message/information/index',
  '/message/notice/index',
  '/message/bannar/index',
  'books/bookList/index',
  'books/book/bookList',
  '/education/datum/index',
  '/education/video/index',
  '/education/consensus/index',
  '/education/plan/index',
  '/education/plan/add',
  '/library/range/index',
  '/library/datum/index',
  '/library/navigation/index',
  '/library/advertisement/index',
  '/message/information/informationOperate',
  '/message/notice/noticeOperate',
  '/message/bannar/bannerOperate',
  '/education/datum/datumAdd',
  '/education/video/videoAdd',
  '/education/consensus/consensusAdd',
]
let keepAliveUrl = [
  '/system/library/Administrator',
]
const gotoLoaction = (to, next) => {
  const permission = authStore()
  const userStore = adminStore()
  if (COMMON_API.validatenull(permission.getAddRouters)) {

    userStore.getInfo().then(res => {
      permission.GenerateRoutes(res).then((role) => {
        // permission.GenerateRoutes(routers).then((role) => {
        role.forEach(v => {
          if (v.children && v.children.length) { // addRoute会删除前一个name相同的路由
            v.children.forEach((i, index) => {
              i.name = i.name + index
              if (i.children) {
                i.children.forEach((e, idx) => {
                  e.name = e.name + idx
                })
              }
            })
          }
          router.addRoute(v)
        })
        router.addRoute({ path: "/:pathMatch(.*)", component: '../../router/404/404.vue', redirect: '/404' })
        next({ ...to, replace: true })
      })
    }).catch((err) => {
      // console.log("权限接口异常", err, window.location, window.location.hash);
      userStore.logOut()
      // ElNotification({ type: 'error', title: '错误', message: '验证失败，请重新登录' })
      userStore.SET_MENU_ALL([])
      next({ path: '/' })
    })
  } else {
    next()
  }
}
router.beforeEach((to, from, next) => {

  let libraryId = getStore({ name: 'libraryId' })
  const store = commonStore()

  store.SET_ACTIVE_INDEX(to.path)
  // 开启progress bar
  NProgress.start();

  // 兼容无需后台权限的手机端页面
  let isMobilePage = window.location.hash.startsWith("#/mobile/");
  if (isMobilePage) {
    next()
    return;
  }

  const token = getStore({ name: 'userToken' })
  if (!COMMON_API.validatenull(token)) {
    if (to.path === '/') {
      next({ path: '/oms/dashboard' })
    } else if (menulibrary.includes(to.path)) {
      // ssssrouter.replace('/message/notice/noPermission')
      if (!libraryId) {
        next({ path: '/message/notice/noPermission' })
      } else {
        gotoLoaction(to, next)
      }
    } else {

      gotoLoaction(to, next)


    }
  } else {
    if (whiteList.includes(to.path)) {
      next()
    } else {
      NProgress.done();
      next({ path: '/' })
    }
  }
});
router.afterEach((to, form) => {
  // 关闭progress bar
  NProgress.done();
  if (to?.meta?.title) {
    document.title = to.meta.title;
    return;
  }
  document.title = `${to.name ? to.name.replace(/[0-9]+/g, "") : ''} - ${libraryTitle}`
  // store.commit('SET_TITLE', to.name)
})

router.onError((error) => {
  console.log('跳转错误')
  const pattern = /Loading (.)+ failed/g;
  if (pattern.test(error.message)) {
    console.log(error.message, 'error.message')
    // location.reload();
  }
});
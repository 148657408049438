<template>
  <div>
    <div class="statistics">
      <div class="title">
        <span class="line"></span>
        {{props.title}}
        <el-tooltip class="box-item"  effect="dark" v-if="props.leftIsTooltip"
            :content="props.leftTooltipText" placement="top">
            <img src="@/assets/img/library/statistics/bangzhu.png" width="16" height="16"
              style="margin-left:6px;cursor: pointer; margin-top:2px;" alt="">
          </el-tooltip>
        <slot name="accumulate"></slot>
      </div>

      <div class="operate">
        <!-- <div class="deadline" v-if="props.isTime">
          截止{{currentDate}}
        </div> -->

        <div class="operate-select">
          <slot name="operate"></slot>
          <div class="date-select" v-if="props.isSun && props.isBtn">
            <div class="day" v-for="item in dayList" :key="item.value"
              :class="currentValue === item.value?'select-day':''" @click="switchDay(item.value)">
              {{ item.label }}</div>
          </div>
          <div style="display: flex;" v-if="currentValue === 1">
            <el-select v-model="currentTime" placeholder="请选择" class="input-dev" v-if="props.isSun"
              @change="changeTime">
              <el-option v-for="item in timeList" :key="item.value" :label="item.label"
                :value="item.value"></el-option>
            </el-select>
            <!-- 自定义时间 -->
            <div style="width:220px;margin-right:20px" v-if="props.isSun">
              <el-date-picker v-model="createTime" type="daterange" value-format="YYYY-MM-DD"
                :disabled-date="disabledDate" @change="change" range-separator="至"
                :disabled='currentTime !== "2"' start-placeholder="开始日期" end-placeholder="结束日期" />
            </div>

          </div>
          <el-button size="small" @click="exportData" :loading="props.loading"
            v-if="props.isExport">导出数据</el-button>
          <el-tooltip class="box-item" v-if="props.isTooltip" effect="dark"
            :content="props.tooltipText" placement="top">
            <img src="@/assets/img/library/statistics/icon14_info.png" width="14" height="14"
              style="margin-left:8px;cursor: pointer;" alt="">
          </el-tooltip>
        </div>
      </div>
    </div>
    <slot name="accumulate1"></slot>
  </div>
</template>
<script setup>
import { nextTick, onMounted, ref } from 'vue'
import { formatYesterday } from '@/filters/index.js'
import { getDate } from "@/filters/index.js";
const currentDate = ref(formatYesterday())
const emits = defineEmits(['searchTrend', 'uploadExcel'])
const props = defineProps({
  isTime: {
    type: Boolean,
    default: false
  },
  isSun: {
    type: Boolean,
    default: false
  },
  loading: {
    type: Boolean,
    default: false
  },
  isExport: {
    type: Boolean,
    default: true
  },
  isTooltip: {
    type: Boolean,
    default: false
  },
   leftIsTooltip: {
    type: Boolean,
    default: false
  },
   leftTooltipText: {
    type: String,
    default: '',
  },
  tooltipText: {
    type: String,
    default: '可导出全部用户数据',
  },
  isAccumulate: {
    type: Boolean,
    default: false
  },
  title: {
    type: String,
    default: '基础数据'
  },
  //数据
  listData: {
    type: Object,
    default: () => { }
  },
  isBtn: {
    type: Boolean,
    default: true
  },
  currentTimeTemp: {
    type: String,
    default: '7'
  },
  createTimeTemp: {
    type: Array,
    default: []
  },
  type: {
    type: String,
    default: '1'
  },
  isIncludeToday :{
    type: Boolean,
    default: true
  }
})
const dayList = ref([
  { label: '日', value: 1 },
  { label: '月', value: 2 },
])
const timeList = ref([
  { label: '近7天', value: '7' },
  { label: '近30天', value: '30' },
  { label: '近90天', value: '90' },
  { label: '近1年', value: '1' },
  { label: '自定义', value: '2' },
])
const currentValue = ref(1)
const currentTime = ref('7')
const createTime = ref([])
const type = ref(1) //type 1=日 2=月
//日月切换
const switchDay = (val) => {
  currentValue.value = val
  if (val === 2) {
    // currentTime.value = '2'
    createTime.value = []
    type.value = 2;
    getDateTime('1')
  } else {
    type.value = 1;
    // currentTime.value = '7'
    getDateTime('7')
  }
}
//只能选择过往时间
const disabledDate = (time) => {
  let max = new Date(new Date());
  return time.getTime() > max
}
//导出数据
const exportData = () => {
  emits('uploadExcel')
}

//时间区间切换
const changeTime = (val) => {
  createTime.value = []
  getDateTime(val)
}
const getDateTime = (val) => {
  if (val !== '2') {
    const { start, end } = props.isIncludeToday? getDate(val, '1'): getDate(val)
    createTime.value = [start, end]
    emits('searchTrend', { type: type.value, startTime: start, endTime: end, currentTime: currentTime.value, createTime: createTime.value })
  }
}
//自定义时间
const change = () => {

  if (createTime.value) {
    let startTime = createTime.value[0]
    let endTime = createTime.value[1]
    // currentTime.value = '2'
    emits('searchTrend', { type: type.value, startTime, endTime, currentTime: currentTime.value, createTime: createTime.value })
  } else {
    // currentTime.value = '7'
    nextTick(() => {
      getDateTime('7')
    })
  }
}
const init = () => {
  // console.log(props, 'props')
  if (props.createTimeTemp.length > 0) {
    currentTime.value = props.currentTimeTemp
    createTime.value = props.createTimeTemp
    currentValue.value = props.type
  } else {
    currentValue.value = 1
    currentTime.value = '7'
    type.value = 1;
    getDateTime('7')
  }

}
onMounted(() => {
  init()
})
defineExpose({ init })
</script>
<style lang="scss" scoped>
.statistics {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 16px;
  .title {
    font-weight: 600;
    font-size: 16px;
    color: #09172f;
    display: flex;
    align-items: center;
    .line {
      width: 3px;
      height: 17px;
      background: var(--color-primary);
      display: inline-block;
      margin-right: 5px;
    }
  }
  :deep(.el-date-editor.el-input__wrapper) {
    width: 220px !important;
    border: none;
    box-shadow: none;
    background: #f7f8fa;
  }
  .operate {
    text-align: right;
    .deadline {
      font-weight: 600;
      font-size: 12px;
      color: var(--color-primary);
      line-height: 22px;
    }
    .operate-select {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      .date-select {
        display: flex;
        align-items: center;
        .day {
          cursor: pointer;
          width: 36px;
          height: 32px;
          line-height: 32px;
          background: #ffffff;
          border: 1px solid #dcdfe6;
          border-radius: 4px;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-right: 10px;
        }

        .select-day {
          background: #3387f126;
          border: 1px solid #3387f1;
          color: #3387f1;
        }
      }
    }
  }
  .title,
  .operate {
    flex: 1;
  }
}

.input-dev {
  margin-right: 10px;
  width: 90px !important;
  display: inline-block !important;
}
</style>
<!--
 * @Description: 左侧导航菜单
 * @Author: zjc
 * @Date: 2021-12-07 15:26:42
 * @LastEditTime: 2024-05-16 11:31:17
 * @LastEditors: 杨明 ym@geeboo.cn
-->
<template>
  <div class="arcsin-sidebar">
    <el-menu :collapse="isCollapse" background-color="#fff" mode="vertical"
      :collapse-transition="false" :default-active="useStore.menuUrl" :default-openeds="menuName"
      :unique-opened="true" router @select="selectMenu">
      <template v-for="(item) in state.subMenuList" :key="item.authUrl">
        <el-sub-menu v-if="item.children && item.children.length>0" :index="item.authUrl">
          <template #title>
            <svg-icon :icon-class="item.authIcon" class="menu-svg noActiveIcon" />
            <svg-icon icon-class="icon16_common_nav(2)" class="menu-svg activeIcon" />
            <span>{{item.authName}}</span>
          </template>
          <!-- 递归组件 -->
          <nav-menu-item :menus="item.children" :collapse="isCollapse"></nav-menu-item>
        </el-sub-menu>
        <el-menu-item v-else :index="item.authUrl" @click="setMenu(item.authUrl)">
          <svg-icon :icon-class="item.authIcon" class="menu-svg noActiveIcon" />
          <svg-icon icon-class="icon16_common_nav(2)" class="menu-svg activeIcon" />
          <template #title>
            {{item.authName}}
          </template>
        </el-menu-item>
      </template>
    </el-menu>
    <a :href="linkUrl" id="link" style="position: absolute;opacity: 0;" target="_blank"></a>
  </div>
</template>
<script setup>
import { useRouter} from 'vue-router'
import navMenuItem from './navMenuItem.vue'
import { setStore, getStore } from '@/utils/store'
import { COMMON_API } from '@/utils/common'
import { reactive, toRefs, computed, ref, watch } from 'vue';
import { commonStore } from '@/store/common.js'
import { useRoute } from 'vue-router'
import { adminStore } from '@/store/user.js'
import { LdpUrl } from '@/config/index.js'
import { routers } from '@/utils/fixedRouter'
const router = useRouter()
const useStore = adminStore()
const route = useRoute()
const linkUrl = ref(null)
const store = commonStore()
const state = reactive({
  menuName: [],
  validatenull: COMMON_API.validatenull,
  subMenuList: computed(() => useStore.getMenuList),
  isCollapse: computed(() => store.collapse),
  // menuIndex: computed(() => {
  //   if (COMMON_API.validatenull(state.subMenuList)) return
  //   if (!menuHide.includes(route.path)) return route.path
  // })
})
watch(
  () => router.currentRoute.value,
  (newValue) => {
    useStore.setMenuUrl(newValue.path)
  },
  { immediate: true }
)
const selectMenu = (index, indexPath, item, routeResult) => {
  //跳转馆配
  if (!item.index) {
    useStore.OPEN_LDP().then(res => {
      linkUrl.value = `${LdpUrl}?loginTag=${res}`
      setTimeout(() => {
        var a = document.getElementById('link');
        a.click();
      }, 100)
    })
  }
}
const setMenu = (url) => {
  useStore.setMenuUrl(url)
  setStore({ name: 'pageNum', content: 1 })
  let libraryId_new = getStore({ name: 'libraryList' }) ? getStore({ name: 'libraryList' }) : [];
  if (libraryId_new.length > 0) {
    setStore({ name: 'libraryId', content: libraryId_new[0].libraryId })
  }
}
const { menuName, validatenull, isCollapse, menuIndex } = toRefs(state)

</script>
<style lang="scss" scoped>
.arcsin-sidebar {
  background-color: var(--menu-bg);
  padding-top: var(--header-height) !important;
  :deep(::-webkit-scrollbar) {
    width: 0 !important;
  }
  :deep(.el-scrollbar__thumb) {
    display: none;
  }
  :deep(.el-sub-menu .is-active) {
    // background:transparent!important;
  }
  :deep(.el-sub-menu__icon-arrow) {
    svg {
      color: #9ba6ba !important;
    }
  }
  .auth-name {
    color: var(--menu-text-color) !important;
  }
  :deep(.el-sub-menu.is-active),
  :deep(.el-menu-item.is-active) {
    svg {
      color: var(--color-primary);
    }
    .el-menu-item__title,
    .el-sub-menu__title {
      span {
        color: var(--color-primary) !important;
      }
    }
  }
  :deep(.el-menu-item span):hover {
    color: var(--color-primary) !important;
  }
}
</style>
<style>
.el-sub-menu__title {
  color: #fff !important;
  background-color: var(--menu-bg) !important;
  height: 44px !important;
  line-height: revert !important;
  margin-top: 8px;
}

.el-sub-menu__title span {
  padding-top: 2px;
}
.arcsin-sidebar .el-menu-item.is-active span {
  color: var(--color-primary) !important;
  /* background-color: #911501 !important; */
}
.arcsin-sidebar .el-menu-item.is-active {
  position: relative;
  background: var(--menu-bg) !important;
}
.el-sub-menu .activeIcon,
.el-menu-item .activeIcon {
  display: none;
}
.arcsin-sidebar .el-menu-item.is-active {
  background: var(--color-primary-shallow) !important;
  color: var(--color-primary) !important;
}
.arcsin-sidebar .is-active .noActiveIcon {
  display: none;
}
.arcsin-sidebar .is-active .activeIcon {
  display: inline-block;
}

.el-menu-item span {
  color: var(--menu-text-color);
}
.el-menu-item {
  height: var(--menu-item-height) !important;
  position: relative;
  background: var(--menu-bg) !important;
  margin-top: 8px;
}
.arcsin-sidebar .el-menu-item.is-active:after {
  content: "";
  top: 0;
  right: 0;
  bottom: 0;
  width: 3px;
  background: var(--color-primary);
  position: absolute;
}
.el-menu-item:hover {
  color: var(--color-primary) !important;
}
.el-menu-item svg {
  display: block;
  font-size: 16px;
}
.el-menu-item span {
  margin-left: 10px !important;
}
.el-sub-menu__title svg {
  display: block;
  /* font-size: 16px !important; */
}
.el-sub-menu .is-opened {
  /* background: transparent !important; */
}
</style>
<template>
  <div style="display: inline-block;" class="tree-select">
    <el-tooltip :content="contentName" placement="top-start" :disabled="offsetWidth < astrictWidth">
      <el-select :style="{'width': size == 'big'? '280px': '200px'}" v-model="unitId1"
        :placeholder="placeholder" :popper-append-to-body="false" popper-class="none" @clear="clear"
        :clearable="clearable" ref="selectRef">
        <el-option :value="unitId1" class="setstyle" :label="currentNodeLabel">
          <div ref="treeList">
            <el-tree-v2 :data="data" @node-click="changeTree" :expand-on-click-node="false"
              :current-node-key="unitId1" :highlight-current="true"
              :props="{ value: 'id', label: 'name', children: 'children' }" :height="140">
              <template #default="{ node, data }">
                <el-tooltip class="box-item" effect="dark" :content="data.name" v-if="data.show"
                  offset="0" placement="top-start">
                  <span :id="data.id" @mouseover="mouseover(data.id)">{{ data.name }}</span>
                </el-tooltip>
                <span v-else @mouseover="mouseover(data.id)" :id="data.id">{{data.name}}</span>
              </template>
            </el-tree-v2>
          </div>

        </el-option>
      </el-select>
    </el-tooltip>
    <el-checkbox v-if="showCheckbox && showSublevel" style="margin-left:8px;" v-model="checked" label="含子部门"
      size="large" @change="checkChange" />
    <!-- 计算下拉框内容的长度 -->
    <div :class="'div'+unitId1"
      style="font-size:14px;opacity:0;display: inline-block; position: absolute; z-index: -1; left:0;"
      ref="myDiv">
      {{contentName}}
    </div>
  </div>

</template>
<script setup>
import { reactive, shallowRef, toRefs, ref, nextTick, watch, computed, onMounted } from 'vue'
import { adminStore } from '@/store/user.js'
import { stubFalse } from 'lodash';
const userStore = adminStore()
const props = defineProps({
  placeholder: {
    type: String,
    default: '选择部门'
  },
  data: { //下拉列表数据
    type: Array,
    default: () => []
  },
  modelValue: {
    type: String,
    default: ''
  },
  clearable: {
    type: Boolean,
    default: false
  },
  listSelectFalg: { //select的背景边框。列表跟弹窗展示的不一样
    type: Boolean,
    default: true
  },
  checked: { //复选框的参数
    type: Boolean,
    default: true
  },
  showCheckbox: { //显示复选框
    type: Boolean,
    default: true
  },
  activeName: {
    type: String,
    default: ''
  },
  initUnitId: {
     type: String,
     default: ''
  },
  size: { //选择框的宽度(big大号280，mini小号200)
    type: String,
    default: 'big'
  }
})
const emit = defineEmits(['getData', 'update:modelValue', 'getSubDept'])
const contentName = ref(null)
const offsetWidth = ref(null);
const myDiv = ref(null);

const selectRef = ref()
const treeList = ref()
const state = reactive({
  showSublevel: computed(() => userStore.getShowSublevel),
  astrictWidth: 235,
  unitId1: null,
  currentNodeLabel: null,
  checked: props.checked,
  unitId: null, //选中的id
  unitIds: null, //子集
})
state.astrictWidth = props.size == 'big' ? 235 : 155
const { unitId1, currentNodeLabel, checked, astrictWidth, showSublevel } = toRefs(state)

watch(() => props.modelValue, (newVal) => {
  state.unitId1 = newVal
})
watch(() => props.initUnitId, (newVal) => {
      state.unitId1 = newVal || null
})
watch(() => props.activeName, (newVal) => {
    state.currentNodeLabel = newVal || null
})
onMounted(()=> {
    emit('getSubDept', userStore.getShowSublevel)
})

const recursion2 = (arr, id, list) => {
  arr.forEach((item) => {
    if (item.id == id && item.children && item.children.length) {
      list.push(...item.children)
      return
    }
    if (!item.children) return
    if (item.children && item.children.length) {
      recursion2(item.children, id, list)
    }
  })
}
const recursion3 = (arr, list) => {
  arr.forEach((item) => {
    list.push(item.id)
    if (!item.children) return
    if (item.children && item.children.length) {
      recursion3(item.children, list)
    }
  })
}

const checkChange = (val) => {
  // 如果没有父级，不让取消勾选
  if (state.unitId1 == null && !state.checked) {
    state.checked = true
    return
  }
  getInformation()
}

const getInformation = async () => {
  state.unitId = state.unitId1 > -1 ? state.unitId1 : null
  state.unitIds = []
  let flag = false //是否包含子级（如果当前组织没有子级，则为false）
  if (state.checked) {
    if (state.unitId1 > -2) {
      let list = []
      await recursion2(props.data, state.unitId1, list)
      await recursion3(list, state.unitIds)
      if (state.unitIds.length) {
        flag = true
      }

      // state.unitId = state.unitId + "," + (state.unitIds.join(','))
    }
  } else {
    flag = false
  }
  if (state.unitIds.findIndex(i => i == state.unitId1) < 0) {
    if (state.unitId1) {
      state.unitIds.unshift(state.unitId1)
    }
  }
   if(!state.showSublevel) { //不包含下级
       flag = false
       state.unitIds = state.unitId1? [state.unitId1] : null
    }
  setTimeout(() => {
    emit('update:modelValue', state.unitId1)
    emit('getData', state.unitId, state.unitIds, flag, state.currentNodeLabel)
  }, 50)
}


const changeTree = (data) => {
  selectRef.value.visible = false
  if (state.unitId1 != data.id) {
    state.unitId1 = data.id
    state.currentNodeLabel = data.name
    getSealName()
    getInformation()
  }


}
//  递归
const recursion = (arr, id, falg) => {
  arr.forEach((item) => {
    if (item.id == id) {
      item.show = falg ? false : true
    }
    if (!item.children) return
    if (item.children && item.children.length) {
      recursion(item.children, id, falg)
    }
  })
}

const getWidth = () => {
  setTimeout(() => {
    offsetWidth.value = Number(document.querySelector(`.div${state.unitId1}`).offsetWidth)
  })
}
const getSealName = () => {
  getWidth()
  if (!state.unitId1) {
    contentName.value = null
  } else {
    recursion1(props.data)

  }
}
const recursion1 = (arr) => {
  arr.forEach((item) => {
    if (item.id == state.unitId1) {
      contentName.value = item.name
    }
    if (!item.children) return
    if (item.children && item.children.length) {
      recursion1(item.children)
    }
  })
}

const mouseover = (event1) => {
  var object = document.getElementById(event1)
  var objectTreeList = treeList.value
  let rectObject = object.getBoundingClientRect();
  let rectObjectTreeList = objectTreeList.getBoundingClientRect();
  if ((rectObjectTreeList.right - rectObject.right) < 2) {
    recursion(props.data, event1)
  }

}
const clear = () => {
  recursion(props.data, state.unitId1, true)
  state.currentNodeLabel = null
  state.unitId1 = null
  state.checked = true
  getInformation()
  getSealName()
}

const reset = () => {
  state.checked = true
  clear()
}

defineExpose({ reset })


</script>
<style lang="scss" scoped>
.tree-select {
  :deep(.el-input__wrapper) {
    background-color: #f7f8fa;
    box-shadow: 0 0 0 1px #f7f8fa;
  }
  :deep(.el-checkbox) {
    height: 30px;
    vertical-align: top;
  }
}
</style>
<style>
.none .el-select-dropdown__item {
  padding: 0;
}
.el-tree--highlight-current .el-tree-node.is-current > .el-tree-node__content {
  color: #3387f1;
}
</style>
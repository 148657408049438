<!--
 * @Description: 
 * @Author: zjc
 * @Date: 2023-08-01 15:44:00
 * @LastEditTime: 2023-09-26 10:40:45
 * @LastEditors: xlm
-->
<template>
  <view class="data-overview">
    <el-row style="width:100%">
      <el-col :span="24/list.length" class="overview-detail" v-for="(item,index) in list"
        :key="index">
        <div class="overview-title">
          <img :src="item.icon" alt="">
        </div>
        <div class="overview-num">
          <div v-if="!validatenull(props.listData)">
            <div v-if="item.isCount">
              <count-to :startVal='0' :endVal='props.listData[item.prop] || 0' :decimals="item.num"
                :duration='3000'>
              </count-to>
              <span class="time-format" v-if="item.unit">{{item.unit}}</span>
            </div>

            <div v-else>
              <span v-if="props.listData.timeObj.h>0">
                <span>{{props.listData.timeObj.h}}</span>
                <span class="time-format">时</span>
              </span>
              <span v-if="props.listData.timeObj.mu>0">
                <span>{{props.listData.timeObj.mu}}</span>
                <span class="time-format">分</span>
              </span>
              <span v-if="props.listData.timeObj.se>0 && props.isShowSecond">
                <span>{{props.listData.timeObj.se}}</span>
                <span class="time-format">秒</span>
              </span>
              <span v-if='props.isShowSecond && props.listData.timeObj.h == 0 && props.listData.timeObj.mu == 0 && props.listData.timeObj.se == 0'>0</span>
              <span v-if='!props.isShowSecond && props.listData.timeObj.h == 0 && props.listData.timeObj.mu == 0'>0<span class="time-format">分</span></span>
            </div>
          </div>
          <div v-else class="overview-num">0</div>
          <div class="over-title">{{item.title}}
            <el-tooltip class="box-item" v-if="item.tooltip" effect="dark" content="当前总笔记数不包含已删除的笔记"
              placement="top">
              <img src="@/assets/img/library/statistics/icon14_info.png" width="14" height="14"
                style="margin-left:3px;cursor: pointer;" alt="">
            </el-tooltip>
          </div>
        </div>

      </el-col>
    </el-row>
  </view>
</template>
<script setup>
import { CountTo } from 'vue3-count-to'
import { COMMON_API } from "@/utils/common";
const validatenull = COMMON_API.validatenull //判断空
const props = defineProps({
  list: {
    type: Array,
    default: () => []
  },
  //数据
  listData: {
    type: Object,
    default: () => { }
  },
  isShowSecond: {
	type: Boolean,
	default: true  
  }
})
</script>
<style lang="scss" scoped>
.data-overview {
  .time-format {
    color: #09172f;
    font-size: 15px;
  }
  display: flex;
  justify-content: space-between;
  height: 110px;
  background: #fff;
  margin-bottom: 10px;
  .overview-detail {
    display: flex;
    align-items: center;
    .overview-title {
      font-weight: 400;
      font-size: 14px;
      color: #9ba6ba;
      line-height: 22px;
      margin-left: 20px;
      img {
        width: 50px;
        height: 50px;
        margin-right: 20px;
      }
    }
    .overview-num {
      font-weight: 500;
      font-size: 30px;
      color: #09172f;
    }
    .over-title {
      font-weight: 400;
      font-size: 14px;
      color: #9ba6ba;
      margin-top: 8px;
      display: flex;
      align-items: center;
      i {
        cursor: pointer;
      }
    }
  }
}
.overview-detail:last-child {
  border-right: none;
}
</style>